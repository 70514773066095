<template>
  <main-content>
    <el-form
      :model="form"
      ref="formRef"
      label-width="100px"
      style="width: 700px"
      :rules="rules"
    >
      <el-form-item label="规则名称:" prop="ruleName">
        <el-input
          maxlength="20"
          v-model.trim="form.ruleName"
          placeholder="请输入规则名称"
        />
      </el-form-item>
      <el-form-item label="支付平台:" prop="payPlatform">
        <div class="relative flex items-center">
          <el-select
            v-model="form.payPlatform"
            placeholder="请选择支付平台"
            @change="payPlatformChange"
            class="w-full"
            :disabled="!!form.id"
          >
            <el-option
              v-for="item in payPlatformOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <div class="absolute ss-refresh">
            <el-link :underline="false" @click="handleRefresh"
              ><i class="el-icon-refresh"></i
              ><span>刷新商户号数据</span></el-link
            >
          </div>
        </div>
      </el-form-item>
      <el-form-item label="轮询商户号:" :required="true">
        <el-form-item
          label=""
          prop=""
          v-for="(item, index) in form.accountList"
          :key="index"
          class="pb-20"
        >
          <div class="flex items-end">
            <el-form-item
              label=""
              :prop="'accountList.' + index + '.appId'"
              class="flex-none w-200"
              :rules="{
                required: true,
                message: '请选择商户号！',
                trigger: 'blur',
              }"
            >
              <div class="itemTitle" v-if="!index">收款商户号</div>
              <el-select
                clearable
                v-model="item.appId"
                placeholder="请选择商户号"
                class="w-full"
                @visible-change="validAccountFocus"
              >
                <el-option
                  v-for="value in validAccountList"
                  :key="value.appId"
                  :label="`${value.appId}(${value.payCompanyName})`"
                  :value="value.appId"
                  :disabled="accountDisabled(value.appId)"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="" class="flex-none w-220">
              <div class="itemTitle" v-if="!index">每日收款限额</div>
              <div class="flex items-center">
                <el-input-number
                  v-model="item.dailyTradeAmountLimit"
                  controls-position="right"
                  class="ss-input-number"
                  :min="1"
                  :max="999999999"
                  :precision="0"
                  :controls="false"
                  placeholder="请输入收款限额"
                ></el-input-number>
                <div class="flex-none ml-10">元 / 日</div>
              </div>
              
            </el-form-item>
            <el-form-item>
              <div class="itemTitle" v-if="!index">预警值</div>
              <div class="flex items-center">
                <el-input-number
                    v-model="item.warningAmountLimit"
                    controls-position="right"
                    class="ss-input-number"
                    :min="1"
                    :max="999999999"
                    :precision="0"
                    :controls="false"
                    placeholder="请输入预警值"
                ></el-input-number>
                <div class="flex-none ml-10">元 / 日</div>
              </div>
            </el-form-item>
            <el-button
              icon="el-icon-plus"
              circle
              @click="handleAdd"
              v-if="index == form.accountList.length - 1"
            ></el-button>
            <el-button
              icon="el-icon-delete"
              circle
              @click="handleDel(index)"
              v-if="form.accountList.length > 1"
            ></el-button>
          </div>
        </el-form-item>
      </el-form-item>
      <el-form-item style="text-align: left">
        <el-button @click="handleCancel">取消</el-button>
        <el-button type="primary" :loading="loading" @click="handleSubmit"
          >保存</el-button
        >
      </el-form-item>
    </el-form>
  </main-content>
</template>

<script>
import MainContent from '@/components/MainContent.vue';
import payCenterApi from '@/api/paycenter';
import { times, divide } from 'number-precision';
import { cloneDeep } from 'lodash-es';

export default {
  components: { MainContent },
  data() {
    return {
      loading: false,
      form: {
        id: '',
        customerId: 'defaultId',
        ruleName: '',
        payPlatform: '',
        payPlatformName: '',
        accountList: [{ appId: '', dailyTradeAmountLimit: undefined,warningAmountLimit:undefined}],
      },
      payPlatformOptions: [],
      rules: {
        ruleName: [{ required: true, message: '请输入规则名称！' }],
        payPlatform: [{ required: true, message: '请选择支付平台！' }],
      },
      validAccountList: [],
    };
  },
  computed: {
    accountDisabled() {
      return e => {
        return this.form.accountList.findIndex(j => j.appId === e) > -1;
      };
    },
  },
  async created() {
    // 获取支付平台列表
    this.getPayPlatformList();
    if (this.$route.params.id) {
      const res = await payCenterApi.getRuleDetail(this.$route.params.id);
      if (res.data) {
        this.form = res.data;
        this.form.accountList.forEach(e => {
          e.dailyTradeAmountLimit =
            divide(e.dailyTradeAmountLimit, 100) || undefined;
          e.warningAmountLimit =
              divide(e.warningAmountLimit, 100) || undefined;

        });
        this.getValidAccount(this.form.payPlatform);
      }
    }
  },
  methods: {
    async getPayPlatformList() {
      try {
        const { data } = await payCenterApi.getValidPayPlatform({
          customerId: 'defaultId',
        });
        this.payPlatformOptions = data;
      } catch (err) {
        console.log(err);
      }
    },
    async getValidAccount(payPlatform) {
      const { data } = await payCenterApi.getValidAccount({
        customerId: 'defaultId',
        payPlatform,
        id: this.form.id,
      });
      this.validAccountList = data;
    },
    handleAdd() {
      this.form.accountList.push({
        appId: '',
        dailyTradeAmountLimit: undefined,
        warningAmountLimit:undefined
      });
    },
    validAccountFocus(e) {
      this.validAccountList = [];
      if (e) {
        this.getValidAccount(this.form.payPlatform);
      }
      console.log(e);
    },
    handleSubmit() {
      this.$refs.formRef.validate(async valid => {
        if (valid) {
          const form = cloneDeep(this.form);
          form.accountList = form.accountList.map(e => {
            return {
              ...e,
              dailyTradeAmountLimit: e.dailyTradeAmountLimit
                ? times(e.dailyTradeAmountLimit, 100)
                : undefined,
              warningAmountLimit: e.dailyTradeAmountLimit
                  ? times(e.warningAmountLimit, 100)
                  : undefined,

            };
          });
          try {
            this.loading = true;
            await payCenterApi.createRule(form);
            this.loading = false;
            this.$message.success(
              this.form.id ? '规则编辑成功' : '规则创建成功',
            );
            this.handleCancel();
          } catch (error) {
            this.loading = false;
          }
        }
      });
    },
    handleCancel() {
      this.$router.back();
    },
    handleDel(index) {
      this.form.accountList.splice(index, 1);
    },
    payPlatformChange() {
      this.form.payPlatformName = this.payPlatformOptions.find(
        e => e.value === this.form.payPlatform,
      ).label;
      this.form.accountList.forEach(e => {
        e.appId = '';
        e.dailyTradeAmountLimit = undefined;
        e.warningAmountLimit = undefined
      });
    },
    async handleRefresh() {
      if (!this.form.payPlatform)
        return this.$message.error('请选择支付平台！');
      await this.getValidAccount(this.form.payPlatform);
      this.$message.success('刷新成功！');
    },
  },
};
</script>

<style scoped lang="less">
.w-200 {
  width: 200px;
}
.w-220 {
  width: 220px;
}
.ss-input-number /deep/ .el-input__inner {
  text-align: left;
}
.ss-refresh {
  right: -130px;
}
.itemTitle{
  color: #606266;
}
</style>
